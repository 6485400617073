* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

html,
body,
#root {
  position: relative;
  overflow-x: hidden;
  background-color: #fff;
  min-height: 100%;
  height: 100%;
}
